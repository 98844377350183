import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../AppActions';
import withIsInApp from '../../withIsInApp';
import withPageEntry from '../../withPageEntry';
import DataJson from '../../../data.json';
import {ArrowBack} from '@styled-icons/boxicons-regular/ArrowBack';

function ArrearagePage(props) {
  const [user] = useOutlet('user');
  const [records, setRecords] = React.useState([]);

  const fetchData = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      AppActions.delay(500);
      setRecords([
        {title: 'AAA', total: 100, status: 'pending'},
        {title: 'BBB', total: 200, status: 'pending'},
        {title: 'CCC', total: 300, status: 'pending'},
      ]);
      /*
      let {joins, lectures} = await ApiUtil.req(
        `${DataJson.apiHost}/study/joins?token=${user.token}`,
        {
          method: 'POST',
          data: {
            first_years: [],
          },
        },
      );
      */
    } catch (err) {
      console.warn(err);
    }
    AppActions.setLoading(false);
  }, [user?.token]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      <h2 className="title">待補費用</h2>
      <div className="container">
        <Ant.Table
          columns={[
            {
              title: '名稱',
              dataIndex: 'title',
              key: 'title',
              width: 200,
              fixed: 'left',
            },
            {
              title: '金額',
              dataIndex: 'total',
              key: 'total',
            },
            {
              title: '狀態',
              dataIndex: 'status',
              key: 'status',
            },
          ]}
          dataSource={records}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;

  max-width: var(--containerMaxWidth900px);
  width: 100%;

  & > .title {
    margin-bottom: 10px;
  }
`;

export default withIsInApp(withPageEntry(ArrearagePage));
